import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css';
import { SocialIcon } from 'react-social-icons';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';

export const Email = () => {

  const { t, i18n } = useTranslation();
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_3zc1qwb', 'template_1dwdaaq', form.current, 'OiH3MLkjpaRl1mICN')
      .then(
        (result) => {
          console.log(result.text);
          toast.success('Mesajınız Başarıyla Gönderildi!', {
            position: toast.POSITION.TOP_CENTER
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <div className='contact-mail-social'>
      <div className='contact-social'>
        <h2 className='projects-header'>{t('Sosyal Medya Hesaplarımız')} </h2>
        <div className='social'>
          <SocialIcon className='icon1' target="_blank" rel="noopener noreferrer" url="https://twitter.com/janberkaltay" />
          <SocialIcon className='icon1' target="_blank" rel="noopener noreferrer" url="https://www.instagram.com/janberkaltay/" />
          <SocialIcon className='icon1' target="_blank" rel="noopener noreferrer" url="https://www.linkedin.com/in/janberkaltay/" />
        </div>
      </div>
      
      <div className='contact'>
        <ToastContainer />

        <form ref={form} onSubmit={sendEmail}>

          <h2 className='projects-header'>{t('Bize Ulaşın')} </h2>

          <div className='form-input'>
            <input className='input' type="name" name="name" placeholder= {t('İsim Soyisim')} />
            <input className='input' type="email" name="email" placeholder={t('Mail Adresiniz')} />
          </div>

          <div className='form-input'>
            <input className='input' type="phone" name="phone" placeholder={t('Telefon Numaranız')} />
            <input className='input' type="text" name="subject" placeholder={t('Konu')}/>
          </div>

          <div><textarea name="message" placeholder={t('Lütfen mesajınızı giriniz')} /></div>
          <Button style={{ backgroundColor: 'blueviolet' }} variant="contained" endIcon={<SendIcon />} type="submit">{t('Gönder')} </Button>

        </form>
      </div>
      
      </div>
  );
};
