import React from 'react'
import { useTranslation } from 'react-i18next';
import './Service4.css';

export default function Service4() {
  const { t, i18n } = useTranslation();
  return (
    <div className='Service4-page'>
      <div className="Service4">
        <div className="Service4-detail">
          <h1 className="">{t("Sistem Odası Kurulumu")}</h1>
          <div>
            {t("Sistem odası, işletmelerin veya kuruluşların veri ve iletişim ekipmanlarını barındırdığı özel tasarlanmış bir alandır. Bu odalar, sunucular, veri depolama cihazları, ağ bileşenleri ve diğer kritik altyapı elemanları gibi hassas donanımları barındırmak ve korumak için kullanılır. Sistem odası kurulumu, modern işletmeler için birçok avantaj sağlar ve iş sürekliliği ile veri güvenliğini önemli ölçüde artırır.")}
          </div>
          <div>
            {t("Sistem odası kurulumunun en belirgin avantajlarından biri, veri ve donanımın güvenliğinin sağlanmasıdır. Bu odalarda kullanılan güvenlik sistemleri, sınırlı erişim kontrolü, izleme ve diğer güvenlik önlemleri sayesinde hassas verilerin yetkisiz erişime karşı korunmasını sağlar. Böylece, veri kaybı, veri sızıntısı veya güvenlik ihlalleri gibi riskler minimum seviyeye indirgenir.")}
          </div>
          <div>
            {t("Sistem odası kurulumu aynı zamanda iş sürekliliği ve felaket kurtarma stratejileri açısından da kritik bir rol oynar. İşletmeler, olası bir kesinti durumunda sistem odalarında yedekleme ve kurtarma planları sayesinde iş sürekliliğini sağlayabilirler. Böylece, veri kaybı veya iş kesintileri gibi olumsuz etkiler minimum düzeye indirilir.")}
          </div>
          <div>
            {t("Sonuç olarak, sistem odası kurulumu işletmelere veri güvenliği, donanım performansı ve iş sürekliliği gibi kritik avantajlar sunar. Bu odalar, işletmelerin verilerini güvende tutmalarını ve günümüzün dijital dünyasında rekabet avantajı elde etmelerini sağlar.")}
          </div>

        </div>
        <div className="img-containers">
          <img
            className='Service-detail-image'
            src="images/sistem7.webp"
            alt="Fiber Optik Sonlandırma" />
          <img
            className='Service-detail-image'
            src="images/sistem6.jpg"
            alt="Fiber Optik Sonlandırma" />
          <img
            className='Service-detail-image'
            src="images/sistem4.jpg"
            alt="Fiber Optik Sonlandırma" />
        </div>
      </div>
      <div className="img-containers4">
        <img
          className='Service-detail-image4'
          src="images/sistem.jpeg"
          alt="Fiber Optik Sonlandırma" />
        <img
          className='Service-detail-image4'
          src="images/sistem5.jpg"
          alt="Fiber Optik Sonlandırma" />
        <img
          className='Service-detail-image4'
          src="images/sistem3.jpeg"
          alt="Fiber Optik Sonlandırma" />

        <img
          className='Service-detail-image4'
          src="images/sistem2.jpeg"
          alt="Fiber Optik Sonlandırma" />
      </div>
    </div>
  )
}
