import React from 'react'
import { useTranslation } from 'react-i18next';
import './Service2.css';

export default function Service2() {
    const { t, i18n } = useTranslation();
    return (
        <div className='Service2-page'>
            <div className="Service2">
                <div className="Service2-detail2">
                    <h1 className="">{t("Fiber Kablo Çekimi")}</h1>
                    <div>
                    {t("Fiber Kablo Çekimi Detay 1")}

                    <div>
                    {t("Fiber Kablo Çekimi Detay 2")}
                    </div>

                    <div>
                    {t("Fiber Kablo Çekimi Detay 3")}
                    </div>

                    {t("Fiber Kablo Çekimi Detay 4")}
                    </div>
                </div>
                <div className="img-containers">
                    <img
                        className='Service-detail-image'
                        src="images/modem.png"
                        alt="Fiber Optik Sonlandırma" />
                    <img
                        className='Service-detail-image'
                        src="images/fiber_altyapi.jpg"
                        alt="Fiber Optik Sonlandırma" />
                    <img
                        className='Service-detail-image'
                        src="images/fiber-kablo.jpg"
                        alt="Fiber Optik Sonlandırma" />
                </div>
            </div>
            <div className="img-containers2">
                <img
                    className='Service-detail-image2'
                    src="images/fiber.png"
                    alt="Fiber Optik Sonlandırma" />

                <img
                    className='Service-detail-image2'
                    src="images/fiberKablo2.jpeg"
                    alt="Fiber Optik Sonlandırma" />
                <img
                    className='Service-detail-image2'
                    src="images/fiberKablo5.jpeg"
                    alt="Fiber Optik Sonlandırma" />
                <img
                    className='Service-detail-image2'
                    src="images/fiberKablo.jpeg"
                    alt="Fiber Optik Sonlandırma" />
            </div>
        </div>
    )
}
