import React from 'react';
import './Services.css';
import { createCards } from './ServicesData';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const cards = createCards();
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className='services'>
        <h1>{t('Hizmetlerimiz')}</h1>
      </div>
      <div className='container'>
        {cards.map((card, index) => (
          <div className='card' key={index}>
            <img src={card.image} alt={card.name} width={200}/>
            <p className="card-header">{t(card.name)}</p>
            <p className="description">{t(card.description)}</p>
            <a href={card.page}><button className='button'>{t('Detaylı Bilgi')}</button></a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Services;
