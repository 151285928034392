import React from 'react';
import { Link } from "react-router-dom";

const servicesData = [
    {
      id: 1,
      image: '/images/sonlandırma.jpg',
      name: 'Fiber Optik Kablo Eki / Sonlandırma',
      description: 'Profesyonel ekipmanlar ve uzman ekibimizle fiber optik kablolarını en üst performansta ve güvenilirlikte bağlantılarını sağlıyoruz.',
      page: '/fiber-sonlandirma'
    },
    {
      id: 2,
      image: '/images/fiber-kablo.jpg',
      name: 'Fiber Kablo Çekimi',
      description: 'En son teknolojiyle donatılmış ekipmanlarımız ve uzman personelimizle, hızlı ve güvenilir fiber altyapı çekim hizmeti sunuyoruz.',
      page: '/fiber-kablo-ve-altyapi-cekimi'
    },
    {
      id: 3,
      image: '/images/kazı.jpeg',
      name: 'Fiber internet Altyapı Kazı Çalışmaları',
      description: 'Titizlikle planlanmış ve uygulanmış kazı teknikleriyle, modern fiber internet altyapısının kurulumunu hızlı ve sorunsuz bir şekilde gerçekleştiriyoruz.',
      page: '/fiber-internet-altyapi-calismalari'
    },
    {
      id: 4,
      image: '/images/sistem-odasi.jpg',
      name: 'Sistem Odası Kurulumu',
      description: 'İhtiyaçlarınıza uygun özelleştirilmiş sistem odalarıyla, işletmenizin veri depolama ve işlem gücü ihtiyaçlarını en verimli şekilde karşılamak üzere profesyonel sistem hizmetleri sunuyoruz.',
      page: '/sistem-odasi-kurulumu'
    },
    /*
        {
      id: 5,
      image: '/images/kamera.jpg',
      name: 'Kamera Sistemleri',
      description: 'Güvenlik ihtiyaçlarınıza yönelik en son teknoloji kamera sistemleriyle, mülkünüzü veya işletmenizi 7/24 etkin bir şekilde izlemenizi sağlayan kapsamlı çözümler sunuyoruz.',
      page: '/kamera-sistemleri'
    },
    */
  ];

  const createCards = () => {
    return servicesData.map(card => {
      return {
        ...card,
        renderCard: () => (
          <div className='card' key={card.id}>
            <img className='card-img' src={card.image} alt={(card.name)}/>
            <p className="card-header">{(card.name)}</p>
            <p className="description">{(card.description)}</p>
            <Link to={card.page}><button className='button'>{('Detaylı Bilgi')}</button></Link>
          </div>
        ),
      };
    });
  };
  
  export { createCards };
  