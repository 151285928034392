import React from "react";
import "./references.css";
import { useTranslation } from 'react-i18next';

const References = () => {
  const { t, i18n } = useTranslation();

  const references = [
    {
      id: 1,
      title: "Türk Telekom",
      company: "Türk Telekom",
      date: "Mayıs 2021 - Şubat 2023",
      description: "Yüksek performanslı web uygulamaları geliştirdim.",
      logo: "references/telekom.png"
    },
    {
      id: 2,
      title: "Turkcell Superonline",
      company: "Turkcell Superonline",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/Tsol.png"
    },
    {
      id: 3,
      title: "Enerjisa",
      company: "Enerjisa",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/Enerjisa.png"
    },
    {
      id: 4,
      title: "Vodafone",
      company: "Vodafone",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/vodafone-1-logo-png-transparent.png"
    },
    {
      id: 5,
      title: "Turksat Kablo",
      company: "Turksat Kablo",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/turksat_kablo_logo-01.png"
    },
    {
      id: 6,
      title: "TCDD",
      company: "TCDD",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/TCDD.png"
    },
    {
      id: 7,
      title: "Karayolları Genel Müdürlüğü",
      company: "Karayolları Genel Müdürlüğü",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/KGM.png"
    },
    {
      id: 8,
      title: "Türk Hava Yolları",
      company: "Türk Hava Yolları",
      date: "Mart 2019 - Nisan 2021",
      description: "Ölçeklenebilir ve güvenilir yazılım çözümleri tasarladım.",
      logo: "references/Turkish_Airlines_logo_2019_compact.svg.png"
    },
  ];

  return (
    <div className="references">
      <h2>{t('Referanslarımız')}</h2>
      <div className="references-list">
        {references.map((reference) => (
          <div className="reference-item" key={reference.id}>
            <img className="reference-logo" src={reference.logo} alt={reference.company} />
            <div>
              <h4>{reference.title}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default References;

/*
 <p>{reference.company}</p>
              <p>{reference.date}</p>
              <p>{reference.description}</p>
*/