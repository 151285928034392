import React from 'react'
import { useTranslation } from 'react-i18next';
import './Service3.css';

export default function Service3() {
  const { t, i18n } = useTranslation();
  return (
    <div className='Service3-page'>
      <div className="Service3">
        <div className="Service3-detail">
          <h1 className="">{t("Fiber İnternet Altyapı Kazı Çalışmaları")}</h1>
          <div>
            {t("Fiber Altyapı Detay 1")}
          </div>

          <div>
            {t("Fiber Altyapı Detay 2")}
          </div>

          <div>
            {t("Fiber Altyapı Detay 3")}
          </div>

        </div>
        <div className="img-containers">
          <img
            className='Service-detail-image'
            src="images/kazı6.jpg"
            alt="Fiber Optik Sonlandırma" />
          <img
            className='Service-detail-image'
            src="images/kazı5.jpg"
            alt="Fiber Optik Sonlandırma" />
          <img
            className='Service-detail-image'
            src="images/kazı.jpeg"
            alt="Fiber Optik Sonlandırma" />
        </div>
      </div>
      <div className="img-containers3">
        <img
          className='Service-detail-image3'
          src="images/kazı1.jpeg"
          alt="Fiber Optik Sonlandırma" />
        <img
          className='Service-detail-image3'
          src="images/kazı2.jpeg"
          alt="Fiber Optik Sonlandırma" />
        <img
          className='Service-detail-image3'
          src="images/kazı3.jpg"
          alt="Fiber Optik Sonlandırma" />
        <img
          className='Service-detail-image3'
          src="images/kazı7.jpeg"
          alt="Fiber Optik Sonlandırma" />
      </div>
    </div>
  )
}
