import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import Switch from "react-switch";

const handleLinkClick = () => {
    window.scrollTo(0, 0); 
  };

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        setChecked(i18n.language === 'en');
    }, [i18n.language]);

    const handleWhatsappClick = () => {
        window.open('https://wa.me/5455406281', '_blank', 'rel="noopener noreferrer"');
    };

    const toggleLanguage = () => {
        const newLanguage = checked ? 'tr' : 'en';
        setChecked(!checked);
        i18n.changeLanguage(newLanguage)
            .then(() => console.log("Language changed successfully"))
            .catch((err) => console.error("Something went wrong while changing language: ", err));
    };

    return (
        <nav>
            <Link to='/' className="title">
                <img src="images/logo.png" alt="Logo" width={150} />
            </Link>
            <div className="menu" onClick={() => {
                setMenuOpen(!menuOpen);
            }}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <ul className={menuOpen ? 'open' : ''}>
                <li> <NavLink to='/' onClick={handleLinkClick}>{t('Anasayfa')}</NavLink></li>
                <li> <NavLink to='/services' onClick={handleLinkClick}>{t('Hizmetlerimiz')}</NavLink></li>
                <li> <NavLink to='/about' onClick={handleLinkClick}>{t('Hakkımızda')}</NavLink></li>
                <li> <NavLink to='/referances' onClick={handleLinkClick}>{t('Referanslarımız')}</NavLink></li>
                <li> <NavLink to='/contact' onClick={handleLinkClick}>{t('İletişim')}</NavLink></li>
                <Link onClick={handleWhatsappClick} className="sale">{t('Teklif Al')}</Link>
                <li className="language" style={{ display: "flex", alignItems: "center", marginRight: 140 }}>
                    <span style={{ marginRight: "10px" }}>
                        <img src="images/TR.png" alt="TR" width={30} height={30} />
                    </span>
                    <Switch
                        onChange={toggleLanguage}
                        checked={checked}
                    />
                    <span style={{ marginLeft: "10px" }}>
                        <img src="images/US.png" alt="EN" width={35} height={35} />
                    </span>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar;
