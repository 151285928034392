import React from "react";
import { Email } from './Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './Contact.css';
import { BusinessCenter, MailLockOutlined, MailOutline, Phone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';


const sendMail = (email) => {
    window.location.href = `mailto:${email}`;
};



const Contact = () => {
const { t, i18n } = useTranslation();

    return (
        <section className='contact-page'>
            <div className='contact-header'>
                <div className="rectangle-container">

                    <div className="rectangle">
                        <div className='contact-icon'><LocationOnIcon fontSize='70' /></div>
                        <h4>{t('Adres')}</h4>
                        <p>Toros mah. Mavi blv. Ardıç apt.</p>
                        <p>NO:54/23</p>
                        <p>Çukurova / ADANA</p>
                    </div>

                    <div className="rectangle">
                        <div className='contact-icon'><Phone fontSize='70' /></div>
                        <h4>{t('Telefon')} </h4>
                        <div className='email-container'>0545 540 62 81</div>
                    </div>

                    <div className="rectangle">
                        <div className='contact-icon'><MailOutline fontSize='70' /></div>
                        <h4>{t('Mail Adresimiz')} </h4>
                        <div className='email-container' onClick={() => sendMail('erol.adafon@hotmail.com')}>
                            erol.adafon@hotmail.com</div>
                    </div>

                    <div className="rectangle">
                        <div className='contact-icon'><BusinessCenter fontSize='70' /></div>
                        <h4>{t('Çalışma Saatlerimiz')} </h4>
                        <p>08:00 - 18:00 {t('Hafta içi')}</p>
                        <p>08:00 - 14:00 {t('Cumartesi')} </p>
                    </div>
                </div>
                <Email />
            </div>
        </section>
    )
}

export default Contact