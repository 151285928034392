import React from 'react'
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./Home.css";
import Services from '../Services/Services';
import References from '../References/References';
import About from '../About/About';

export default function Home() {
  const { t, i18n } = useTranslation();

  return (
    <div className='home'>
      <div className='homepage'>
        <div className='header'>
          <h1 className='title'>
            ADAFON Telekomünikasyon
          </h1>
          <p className='text'>
            {t('message')}
          </p>
          <button className='button'>
            <NavLink className='sale-button' to='/services'>{t('Hizmetlerimiz')}</NavLink>
          </button>
        </div>
        <img className='img' src="images/home1.png" alt="Homepage-img" />
      </div>
      <div className='vista-header'>
        <h1 className='vista-title'>{t('vision1')}</h1>
        <h1 className='vista-title'>{t('mission1')}</h1>
      </div>
      <div className='vista'>
        <div className='vista-container'>
          <img src="images/vision.jpeg" alt="vision-img" className='image' width={250} />
          <p className='vista-text'>
          {t('vision')}
          </p>
        </div>
        <div className='vista-container'>
          <p className='vista-text'>
          {t('mission')}
          </p>
          <img src="images/mission.jpeg" alt="mission-img" className='image' width={300}/>
        </div>
      </div>
      <Services/>
      <About/>
      <References/>
    </div>
  )
}
