import React from 'react';
import './About.css';
import { useTranslation } from 'react-i18next';


const About = () => {

  const { t, i18n } = useTranslation();

  return (
    <div className="about-container">
      <h2>{t('Hakkımızda')}</h2>
      <p>
        {t("Adafon Telekomünikasyon, 2008 yılından beri kesintisiz hizmet anlayışıyla telekomünikasyon sektöründe öncü bir konumda yer almaktadır. Yenilikçi ve kaliteli hizmet anlayışını benimseyen firma, iletişim altyapısında devrim yaratan çözümleri ile müşterilerine güvenilir bir iletişim deneyimi sunmayı amaçlamaktadır.")}
      </p>
      <p>
        {t('Fiber Optik Kablo Eki/Sonlandırma, Fiber Kablo Çekimi, Fiber İnternet Altyapı Kazı Çalışmaları, Sistem Odası Kurulumu ve Kamera Sistemleri gibi geniş bir hizmet yelpazesi sunan Adafon, teknolojik gelişmeleri yakından takip ederek sürekli büyümeyi ve gelişmeyi hedeflemektedir. Müşterilerine en son teknolojileri kullanarak en üst düzeyde hizmet sunma amacıyla çalışan Adafon, iletişim altyapılarında dönüşüm sağlamayı ve işletmelerin dijital dönüşüm süreçlerine katkıda bulunmayı amaçlamaktadır.')}
      </p>
      <p>
        {t("Firma, güçlü bir iş ortaklığı ağı oluşturarak sektördeki öncü konumunu pekiştirmeyi ve ulusal ölçekte tanınan bir marka olmayı hedeflemektedir. Adafon'un misyonu, güvenilir, hızlı ve yüksek kaliteli fiber optik hizmetleriyle işletmelerin iletişim altyapılarını güçlendirmek ve dijital dönüşüm süreçlerine katkı sağlamaktır. Müşteri memnuniyetini her zaman ön planda tutan firma, sektördeki öncü teknolojilerle yenilikçi çözümler sunmayı prensip edinmiştir.")}
      </p>
      <p>
        {t('Adafon Telekomünikasyon, profesyonel ekibi ve güçlü altyapısıyla müşterilerine kesintisiz iletişim imkanı sunmayı ve onların iş süreçlerine değer katmayı ilke edinmiştir. Başarıyla gerçekleştirdiği projeler ve sektöre kazandırdığı yeniliklerle, Adafon, telekomünikasyon sektöründeki öncü ve güvenilir marka olma vizyonunu başarıyla sürdürmektedir.')}
      </p>
      <div className="image-container">
        <img src="images/fiber-kablo.jpg" alt="Adafon Image 2" />
        <img src="images/kazı1.jpeg" alt="Adafon Image 2" />
        <img src="images/fiber.png" alt="Adafon Image 2" />
        <img src="images/saha.jpeg" alt="Adafon Image 2" />
      </div>
    </div>
  );
};

export default About;


/*
<div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h1 className="section-title">{t('title')}</h1>
                        <p className="about-text">
                            Firmamız, teknoloji alanında uzmanlaşmış bir ekip tarafından kurulmuştur. Misyonumuz, müşterilerimize yenilikçi ve etkili çözümler sunarak işlerini geliştirmelerine yardımcı olmaktır. Ekibimizdeki deneyimli yazılım geliştiriciler, tasarımcılar ve proje yöneticileri, her müşteriye özelleştirilmiş çözümler sunmak için bir araya gelmiştir.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="counter-item">
                                    <h4>Proje Tamamlama</h4>
                                    <p className="counter">128</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="counter-item">
                                    <h4>Mutlu Müşteriler</h4>
                                    <p className="counter">76</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="counter-item">
                                    <h4>Çözümlerimiz</h4>
                                    <p className="counter">104</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="counter-item">
                                    <h4>Ödüllerimiz</h4>
                                    <p className="counter">12</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h1>{t('title')}</h1>
                    <h2>Temel Prensiplerimiz</h2>
                    <p>{t('principles')}</p>
                    <h2>Misyonumuz</h2>
                    <p>{t('mission2')}</p>
                </div>
            </div>
*/