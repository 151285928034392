import React from 'react'
import { useTranslation } from 'react-i18next';
import './Service1.css';

export default function Service1() {
    const { t, i18n } = useTranslation();
    return (
        <div className='Service1-page'>
        <div className="Service1">
            <div className="Service1-detail">
                <h1 className="">{t("Fiber Optik Sonlandırma")}</h1>
                <div>
                    {t("Fiber optik sonlandırma")}
                </div>

                <div>
                    {t("Fiber Sonlandırma Detay")}
                </div>

            </div>
            <div className="img-containers">
                <img
                    className='Service-detail-image'
                    src="images/fiber-optik-sonlandirma-isciligi-u6288161-l8525 (1).jpg"
                    alt="Fiber Optik Sonlandırma" />
                <img
                    className='Service-detail-image'
                    src="images/fiber-kablo.jpg"
                    alt="Fiber Optik Sonlandırma" />
                <img
                    className='Service-detail-image'
                    src="images/fiber-optik-sonlandirma-isciligi-u6288161-l8525 (1).jpg"
                    alt="Fiber Optik Sonlandırma" />
            </div>
        </div>
        <div className="img-containers1">
                <img 
                    className='Service-detail-image1'
                    src="images/fiberSonlan3.jpeg"
                    alt="Fiber Optik Sonlandırma" />
                <img
                    className='Service-detail-image1'
                    src="images/fiberSonlan4.jpg"
                    alt="Fiber Optik Sonlandırma" />
                <img
                    className='Service-detail-image1'
                    src="images/fiberSonlan2.jpeg"
                    alt="Fiber Optik Sonlandırma" />
                    <img
                    className='Service-detail-image1'
                    src="images/fiberSonlan.jpeg"
                    alt="Fiber Optik Sonlandırma" />
            </div>
            </div>
    )
}
