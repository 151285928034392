import React from 'react';
import './Footer.css'
import { NavLink } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { IconButton } from '@mui/material';
import { Facebook, LinkedIn, Instagram, WhatsApp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const handleLinkClick = () => {
    window.scrollTo(0, 0);
};

const sendMail = (email) => {
    window.location.href = `mailto:${email}`;
};

const Footer = () => {

    const handleFacebookClick = () => {
        window.open('https://www.facebook.com/erol.guzel.319', '_blank', 'rel="noopener noreferrer"');
    };

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/guzeladana_fiberoptiknetwork/', '_blank', 'rel="noopener noreferrer"');
    };

    const handleWhatsappClick = () => {
        window.open('https://wa.me/5455406281', '_blank', 'rel="noopener noreferrer"');
    };


    const { t, i18n } = useTranslation();

    return (
        <footer className='footer'>
            <div>
                <div className="footer-row">
                    <div className="column-1">
                        <img src="images/logo.png" alt="Endless Logo" width={200} />
                        <p>TOROS MAH. MAVİ BLV. ARDIC APT.</p>
                        <p>NO:54/23 </p>
                        <p>ÇUKUROVA / ADANA</p>
                        <a href="tel:545-540-62-81" className='mail-row' ><LocalPhoneIcon className='icon' />0545 540 62 81</a>
                        <p className='mail-row' onClick={() => sendMail('erol.adafon@hotmail.com')}>
                            <MailOutlineIcon className='icon' />
                            erol.adafon@hotmail.com
                        </p>
                    </div>
                    <span></span>
                    <span></span>
                    <div className="column">
                        <h3>Servisler</h3>
                        <NavLink className={'mail-row'} to='/services' onClick={handleLinkClick}>{t('Hizmetlerimiz')}</NavLink>
                        <NavLink className={'mail-row'} to='/about' onClick={handleLinkClick}>{t('Hakkımızda')}</NavLink>
                        <NavLink className={'mail-row'} to='/referances' onClick={handleLinkClick}>{t('Referanslarımız')}</NavLink>
                        <NavLink className={'mail-row'} to='/contact' onClick={handleLinkClick}>{t('İletişim')}</NavLink>
                        <NavLink ><button onClick={handleWhatsappClick} className='button'>{t('Teklif Al')}</button></NavLink>
                    </div>
                    <span></span>
                    <span></span>
                    <div className="column">
                        <h3>{t('Sosyal Medya Hesaplarımız')}</h3>
                        <div>
                            <IconButton onClick={handleFacebookClick}>
                                <Facebook fontSize='large' />
                            </IconButton>
                            <IconButton onClick={handleInstagramClick}>
                                <Instagram fontSize='large' />
                            </IconButton>
                            <IconButton onClick={handleWhatsappClick}>
                                <WhatsApp fontSize='large' />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className='dev'>
                    {t('Tüm Hakları Saklıdır')} &copy; 2023
                    <a href="http://www.endlesstech.org" target="_blank" rel="noopener noreferrer">Endless Software</a>
                    &
                    <a href="http://www.janberkaltay.dev" target="_blank" rel="noopener noreferrer">Janberk Altay</a>
                </div>

            </div>
        </footer>
    );
}
export default Footer;
