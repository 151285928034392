import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Analytics } from '@vercel/analytics/react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Analytics beforeSend={(e) => {
        const url = new URL(e.url)
        url.searchParams.delete('secret')
        return {
          ...e,
          url: url.toString(),
        }
      }} />
    </BrowserRouter>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
    <a href="https://wa.me/5455406281" class="float" rel='noreferrer' target="_blank">
      <i class="fa fa-whatsapp my-float"></i>
    </a>
  </React.StrictMode>
);

reportWebVitals();
