import './App.css';
import { useTranslation } from "react-i18next";
import Home from './components/Home/Home';
import About  from './components/About/About';
import Contact from './components/Contact/Contact';
import Services from './components/Services/Services';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import References from './components/References/References';
import Service1 from './components/ServiceDetails/Service1/Service1';
import Service2 from './components/ServiceDetails/Service2/Service2';
import Service3 from './components/ServiceDetails/Service3/Service3';
import Service4 from './components/ServiceDetails/Service4/Service4';
import Service5 from './components/ServiceDetails/Service5/Service5';

function App() {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);
  }
  return (
    <div className="App">
      <Navbar />
    <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/services' element={<Services />}></Route>
      <Route path='/referances' element={<References />}></Route>
      <Route path='/about' element={<About />}></Route>
      <Route path='/contact' element={<Contact />}></Route>
      <Route path='/fiber-sonlandirma' element={< Service1/>}></Route>
      <Route path='/fiber-kablo-ve-altyapi-cekimi' element={< Service2/>}></Route>
      <Route path='/fiber-internet-altyapi-calismalari' element={< Service3/>}></Route>
      <Route path='/sistem-odasi-kurulumu' element={< Service4/>}></Route>
      <Route path='/kamera-sistemleri' element={< Service5/>}></Route>
    </Routes>
    <Footer />
    </div>
  );
}

export default App;
